


import React from 'react';
import axios from 'axios';
import { Table, Button, Select } from 'antd';

const { Option } = Select;

class App extends React.Component {
  state = {
    datas: [],
    filterStatus: '',
    isLoading: false,
    selectedRowKeys: []
  };

  fetchData = async () => {
    this.setState({ isLoading: true });
    try {
      console.log('fetchData');
      const response = await axios.get('https://api.demngayyeu.vn/admin/noitu/report');
      const data = response.data.data.map(item => ({ ...item, key: item.idreport }));
      // const sortedData = data.sort((a, b) => a.text.localeCompare(b.text));

      this.setState({ datas: data, isLoading: false });
    } catch (error) {
      console.log(error);
      this.setState({ isLoading: false });
    }
  };

  async componentDidMount() {
    await this.fetchData();
    this.getDataOK()
  }

  getDataOK = () => {
    const { datas } = this.state;
    const uniqueTexts = new Set(); // Sử dụng Set để lưu trữ các giá trị text duy nhất
    const okItems = datas.filter(item => {
      return item.status === "ok" && !uniqueTexts.has(item.text) && uniqueTexts.add(item.text);
    });
  
    // Tại điểm này, okItems sẽ chứa các phần tử có status "ok" mà không trùng lặp text
    console.log("okItems", okItems);
    // Bạn có thể cập nhật state hoặc xử lý kết quả tùy theo yêu cầu
  };



  handleDeleteOne = async (id) => {
    this.setState({ isLoading: true });
    console.log('handleDelete');
    try {
      const item = this.state.datas.find((item) => item.idreport === id);
      const { text } = item;

      const itemsToUpdate = this.state.datas.filter((item) => item.text.toLowerCase() === text.toLowerCase());

      await Promise.all(
        itemsToUpdate.map(async (item) => {
          const { idreport, userid, text } = item;
          await axios.post('https://api.demngayyeu.vn/admin/noitu/report', {
            idreport,
            status: 'delete',
            userid,
            text,
          });
        })
      );

      this.setState({
        datas: this.state.datas.filter((data) => !itemsToUpdate.some((item) => item.idreport === data.idreport)),
        isLoading: false,
      });
    } catch (error) {
      console.error('API error:', error);
      this.setState({ isLoading: false });
    }
  };



  handleAdd = async (id) => {
    this.setState({ isLoading: true });
    console.log('handleDelete');
    try {
      const item = this.state.datas.find((item) => item.idreport === id);
      const { text } = item;

      const itemsToUpdate = this.state.datas.filter((item) => item.text.toLowerCase() === text.toLowerCase());

      await Promise.all(
        itemsToUpdate.map(async (item) => {
          const { idreport, userid, text } = item;
          await axios.post('https://api.demngayyeu.vn/admin/noitu/report', {
            idreport,
            status: 'ok',
            userid,
            text,
          });
        })
      );

      this.setState({
        datas: this.state.datas.filter((data) => !itemsToUpdate.some((item) => item.idreport === data.idreport)),
        isLoading: false,
      });
    } catch (error) {
      console.error('API error:', error);
      this.setState({ isLoading: false });
    }
  };
  handleDelete = async (id) => {
    this.setState({ isLoading: true });
    console.log('handleDelete');
    try {
      const item = this.state.datas.find((item) => item.idreport === id);
      const { idreport, userid, text } = item;
      await axios.post('https://api.demngayyeu.vn/admin/noitu/report', {
        idreport,
        status: 'delete',
        userid,
        text,
      });
      this.setState({
        datas: this.state.datas.filter((data) => idreport != data.idreport),
        isLoading: false,
      });
     
    } catch (error) {
      console.error('API error:', error);
      this.setState({ isLoading: false });
    }
  };

  handleCheck = async () => {

    const checkDelete = async () => {
      this.setState({ isLoading: true });

      const {datas} = this.state
      const okTexts = datas.filter(item => item.status === "delete").map(item => item.text);
      const result = datas.filter(item => item.status === "" && okTexts.includes(item.text));
      console.log("delete check:",result.length)
      if(result.length == 0){
        return
      }
      for (const item of result) {
        // Chờ đợi mỗi hành động delete hoàn thành trước khi tiếp tục
        await this.handleDelete(item.idreport);
      }
    
      // Đợi tất cả các hành động delete hoàn thành rồi mới tắt trạng thái loading
      this.setState({ isLoading: false });
    }

    const checkOk = async () => {
      this.setState({ isLoading: true });

      const {datas} = this.state
      const okTexts = datas.filter(item => item.status === "ok").map(item => item.text);
      const result = datas.filter(item => item.status === "" && okTexts.includes(item.text));
      console.log("ok check:", result.length)
      if(result.length == 0){
        return
      }
      for (const item of result) {
        // Chờ đợi mỗi hành động delete hoàn thành trước khi tiếp tục
        await this.handleAdd(item.idreport);
      }
    
      // Đợi tất cả các hành động delete hoàn thành rồi mới tắt trạng thái loading
      this.setState({ isLoading: false });
    }
    await checkDelete()
    await checkOk()
    this.setState({ isLoading: false });

  }

  handleFilterChange = (value) => {
    this.setState({ filterStatus: value });
  };

  onSelectChange = (selectedRowKeys) => {
    console.log('selectedRowKeys changed: ', selectedRowKeys);
    this.setState({ selectedRowKeys });
  };
  xulybuttonXoa = async () =>{
    console.log("xử lý button")
    const {selectedRowKeys} = this.state;
    if (selectedRowKeys.length == 1) {
      this.handleDeleteOne(selectedRowKeys[0])
      
    } else {
      for (const element of selectedRowKeys) {
        await this.handleDelete(element)

      }
     
    }
   

  } 
  xulybuttonThem = async () =>{
    console.log("xử lý button")
    const {selectedRowKeys} = this.state;
    
    for (const element of selectedRowKeys) {
      await this.handleAdd(element)

    }
    
  } 


  render() {
    const { datas, filterStatus, isLoading,  selectedRowKeys} = this.state;
    const filteredDatas = datas // filterStatus === 'all' ? datas : datas.filter((item) => item.status === filterStatus);
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      // Các cài đặt khác cho việc lựa chọn hàng, ví dụ như selections, getCheckboxProps,...
    };

    const columns = [
   
      {
        title: 'Text',
        dataIndex: 'text',
        key: 'text',
        defaultSortOrder: 'descend',
        sorter: (a, b) =>  a.text.localeCompare(b.text),
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        filters: [
          {
            text: 'delete',
            value: 'delete',
          },
          {
            text: 'ok',
            value: 'ok',
          },
          {
            text: 'empty',
            value: "",
          },
        ],
        onFilter: (value, record) => {
          if(value != ""){
            return record.status.indexOf(value) === 0
          } else {
            return record.status == ""
          }
        },
      },
      {
        title: 'Gợi ý',
        key: 'suggestion',
        render: (_, record) => {
          const { text } = record;
          const suggestions = filteredDatas.filter(item => item.text.toLowerCase() === text.toLowerCase() && item.idreport !== record.idreport);
          return (
            <div style={{display:'flex', flexDirection:'row'}}>
              {suggestions.map((item) => (
                <div key={item.idreport}>{`${item.text} - ${item.status}`}</div>
              ))}
            </div>
          );
        },
      }
    ];

    return (
      <div className="App" style={{ padding: 20 }}>
        <h1>Admin Nối từ</h1>
        <div style={{display:'flex', flexDirection:'row', justifyContent:'space-around', width: 600}}>
        <Select value={filterStatus} onChange={this.handleFilterChange} style={{ width: 120, marginBottom: 16 }}>
          <Option value="">Empty</Option>
          <Option value="ok">OK</Option>
        </Select>
        <Button type='default' onClick={this.xulybuttonXoa}> Xử lý xoá</Button>
        <Button type='primary' onClick={this.xulybuttonThem}> Xử lý thêm</Button>
        <Button type='primary' onClick={this.handleCheck}> Kiểm tra lần đầu</Button>

        </div>
      
        <Table size={'small'} rowSelection={rowSelection}  loading={isLoading} dataSource={filteredDatas} columns={columns} bordered  pagination={{ pageSize: 30 }} />
      </div>
    );
  }
}

export default App;
